import { booking } from "@/api/booking.js";
import { carApi } from "@/api/car.js";
import { departureScheduleApi } from "@/api/departureSchedule.js";
import dayjs from "dayjs";

import DatePick from "vue-date-pick";
import "vue-date-pick/dist/vueDatePick.css";

export default {
  components: {
    DatePick,
  },
  data: () => ({
    userData: JSON.parse(localStorage.getItem("userData")) || false,
    timeStart: dayjs().format("YYYY-MM-DD"),
    listDepartureSchedule: [],
    bookForOther: false,
    listNumOfAdult: [1, 2, 3, 4, 5],
    listNumOfChild: [0, 1, 2, 3],
    otherPhone: "",
    numOfAdult: 1,
    numOfChild: 0,
    departureScheduleId: -1,
    pickupPoint: "",
    dropPoint: "",
    isLoadingListDepartureSchedule: false,
    isLoadingCreateBoooking: false,
    carType:"",
    carCapacity: null,
    carPrice: "",
    carDescription: "",
    carLicensePlates: ""
  }),
  created() {
    this.getListDepartureSchedule(this.timeStart);
    if (this.checkRole(["driver"])) {
      this.bookForOther = true;
    }
  },
  watch: {
    timeStart(val) {
      this.getListDepartureSchedule(val);
    },
    departureScheduleId(val){
      this.getDetailCar(this.listDepartureSchedule.find( x => x.ID == val).carId);
    }
  },
  methods: {
    disableDate(date) {
      const currentDate = new Date();
      let featureDate = new Date();
      featureDate.setDate(currentDate.getDate() + 7);

      return date < currentDate || date > featureDate;
    },
    async getListDepartureSchedule(starTime) {
      this.isLoadingListDepartureSchedule = true;

      let params = {
        starTime: starTime,
      };

      await departureScheduleApi
        .getListDepartureSchedule(params)
        .then((res) => {
          if (res.length) {
            // Loại các chuyến đã quá giờ hiện tại
            this.listDepartureSchedule = res.filter(
              (x) =>
                dayjs(x.timeStart).add(10, "minute").valueOf() >
                dayjs().valueOf()
            );

            this.departureScheduleId = this.listDepartureSchedule[0].ID;
          } else {
            this.listDepartureSchedule = [
              {
                ID: -1,
                post_title: "Không có chuyến xe nào",
              },
            ];
          }
        });

      // Render lại select
      this.isLoadingListDepartureSchedule = false;
    },
    async createBooking() {
      if (this.bookForOther && !this.otherPhone) {
        this.$vs.notification({
          color: "danger",
          position: "top-right",
          duration: "2000",
          text: "Bạn chưa nhập đủ thông tin",
        });
        return;
      }

      this.isLoadingCreateBoooking = true;
      let body = {
        numOfAdult: this.numOfAdult,
        numOfChild: this.numOfChild,
        pickupPoint: this.pickupPoint,
        dropPoint: this.dropPoint,
        departureScheduleId: this.departureScheduleId,
        otherPhone: this.bookForOther ? this.otherPhone : "",
      };
      await booking.createBooking(body).then((res) => {
        if (res.id) {
          this.$vs.notification({
            color: "success",
            position: "top-right",
            duration: "2000",
            title: "Đặt ghế thành công !",
          });
          if (
            JSON.parse(localStorage.getItem("userData")).roles.filter(
              (x) => x == "administrator"
            ).length
          ) {
            return this.$router.push({
              name: "adminDashboard",
            });
          } else if (
            JSON.parse(localStorage.getItem("userData")).roles.filter(
              (x) => x == "driver"
            ).length
          ) {
            return this.$router.push({
              name: "driverDashboard",
            });
          } else {
            return this.$router.push({
              name: "memberDashboard",
            });
          }
        }
      });
      this.isLoadingCreateBoooking = false;
    },
    getNameListDepartureSchedule(item) {
      return dayjs(item.timeStart).format("HH:mm") + " - " + item.post_title;
    },
    checkRole(role) {
      let userRole = this.userData.roles;
      if (userRole) {
        var duplicates = role.filter(function (val) {
          return userRole.indexOf(val) != -1;
        });
        return duplicates.length;
      } else {
        return false;
      }
    },
    async getDetailCar(carId) {
      const body = {
        id: carId,
      };
      await carApi.getDetailCar(body).then((res) => {
        this.carType = res.acf.description;
        this.carCapacity = res.acf.capacity;
        this.carPrice = res.acf.price;
        this.carDescription = res.acf.description;
        this.carLicensePlates = res.acf.licensePlates;
      });
      
    },
  },
};
